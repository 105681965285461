import React from "react"

import Seo from "../components/Seo"
import Layout from "../components/Layout"
import ContentContainer from "../components/ContentContainer"
import TextContainer from "../components/TextContainer"

const PrivacyPage = () => {
  return (
    <Layout>
      <Seo title="Polityka prywatności" />
      <ContentContainer>
        <TextContainer>
          <h1>Polityka prywatności</h1>
          <ol>
            <li>
              Administratorem danych jest Posterus z siedzibą ul. Polna 1c/3,
              55-010 Święta Katarzyna.
            </li>
            <li>
              Dane osobowe Administrator gromadzi z należytą starannością oraz
              odpowiednio chroni je przed dostępem do nich osób trzecich i
              nieupoważnionych.
            </li>
            <li>
              Dane są przetwarzane zgodnie z Rozporządzeniem Parlamentu
              Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 roku w
              sprawie ochrony osób fizycznych w związku z przetwarzaniem danych
              osobowych i w sprawie swobodnego przepływu takich danych oraz
              uchylenia Dyrektywy 95/46/WE (RODO) oraz Ustawy z dnia 29 sierpnia
              1997 r. o ochronie danych osobowych.
            </li>
            <li>
              Użytkownikowi przysługują prawa wynikające z wskazanych aktów
              prawnych, w tym w szczególności:
              <ul>
                <li>
                  prawo żądania dostępu do swoich danych, ich sprostowania,
                  usunięcia czy też ograniczenia przetwarzania;
                </li>
                <li>
                  prawo do wniesienia sprzeciwu wobec przetwarzania danych;
                </li>
                <li>prawo do przenoszenia danych;</li>
                <li>
                  prawo do wniesienia skargi do organu nadzorczego w związku z
                  przetwarzaniem i gromadzeniem danych przez Administratora.
                </li>
              </ul>
            </li>
          </ol>
        </TextContainer>
      </ContentContainer>
    </Layout>
  )
}

export default PrivacyPage
